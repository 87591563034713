<template>
  <div style="height:100%; width:100%">
    <!--begin::Content header-->
    <!--end::Content header-->

    <!--begin::Signup-->
    <div v-if="!$root.mobile" class="px-7 mb-10 mb-lg-20">
      <h3 class="font-size-h1">{{ $t("AUTH.REGISTER.TITLE") }}</h3>
      <p class="text-muted font-weight-semi-bold">
        {{ $t("AUTH.REGISTER.DESC") }}
      </p>
    </div>

    <!-- class="elevation-0 p-0 m-0" -->
    <v-stepper
      v-model="step"
      class="elevation-0"
      style="background-color: transparent;"
      :style="$root.mobile ? 'height: 100%' : 'height: calc(100% - 150px);'"
      v-if="!registering"
    >
      <v-stepper-items
        :style="
          $root.mobile
            ? 'height: calc(100% - 72px);'
            : 'height: calc(100% - 158px);'
        "
      >
        <v-stepper-content style="height:100%" step="1">
          <div style="flex-grow:1">
            <div class="text-primary font-weight-bolder font-size-h1">
              {{ $t("AUTH.REG_STEPS.STEP1.title") }}
            </div>

            <v-text-field
              ref="user_name"
              background-color="transparent"
              :rules="nameRules"
              v-model="userForm.full_name"
              flat
              autofocus
              solo
              @keydown.enter.exact.prevent
              @keyup.enter.exact="nextStep"
            >
              <template v-slot:label>
                <span class="text-muted">{{
                  $t("AUTH.REG_STEPS.STEP1.label")
                }}</span>
              </template>
            </v-text-field>
          </div>
        </v-stepper-content>
        <v-stepper-content style="height:100%" step="2">
          <div style="flex-grow:1">
            <div class="text-primary font-weight-bolder font-size-h1">
              {{
                $t("AUTH.REG_STEPS.STEP2.title", {
                  name: userForm.full_name.split(" ")[0]
                })
              }}
            </div>
            <v-text-field
              ref="user_email"
              v-model="userForm.email"
              background-color="transparent"
              :rules="emailRules"
              flat
              lazy-validation
              autofocus
              solo
              @keydown.enter.exact.prevent
              @keyup.enter.exact="nextStep"
            >
              <template v-slot:label>
                <span class="text-muted">{{
                  $t("AUTH.REG_STEPS.STEP2.label", {
                    name: userForm.full_name.split(" ")[0]
                  })
                }}</span>
              </template>
            </v-text-field>
          </div>
        </v-stepper-content>
        <v-stepper-content style="height:100%" step="3">
          <div style="flex-grow:1">
            <div class="text-primary font-weight-bolder font-size-h1">
              {{ $t("AUTH.REG_STEPS.STEP3.title") }}
            </div>
            <v-text-field
              ref="user_phone"
              v-model="userForm.phone"
              background-color="transparent"
              :rules="phoneRules"
              flat
              lazy-validation
              autofocus
              solo
              @keydown.enter.exact.prevent
              @keyup.enter.exact="nextStep"
            >
              <template v-slot:label>
                <span class="text-muted">{{
                  $t("AUTH.REG_STEPS.STEP3.label")
                }}</span>
              </template>
            </v-text-field>
          </div>
        </v-stepper-content>
        <v-stepper-content style="height:100%" step="4">
          <div style="flex-grow:1">
            <div class="text-primary font-weight-bolder font-size-h1">
              {{ $t("AUTH.REG_STEPS.STEP4.title") }}
            </div>
            <v-text-field
              ref="user_pass"
              :type="showpass ? 'text' : 'password'"
              background-color="transparent"
              v-model="userForm.password"
              :rules="[v => !!v || $t('AUTH.REG_STEPS.STEP4.validation[0]')]"
              flat
              lazy-validation
              autofocus
              solo
              @keydown.enter.exact.prevent
              @keyup.enter.exact="nextStep"
              @click:append="showpass = !showpass"
              :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            >
              <template v-slot:label>
                <span class="text-muted">{{
                  $t("AUTH.REG_STEPS.STEP4.label")
                }}</span>
              </template>
            </v-text-field>
          </div>
        </v-stepper-content>
        <v-stepper-content style="height:100%" step="5">
          <div style="flex-grow:1">
            <div class="text-primary font-weight-bolder font-size-h1">
              {{ $t("AUTH.REG_STEPS.STEP5.title") }}
            </div>
            <v-text-field
              ref="user_passr"
              :type="showpass ? 'text' : 'password'"
              v-model="userForm.confirmPassword"
              background-color="transparent"
              :rules="confirmPasswordRules"
              flat
              lazy-validation
              autofocus
              solo
              @keydown.enter.exact.prevent
              @keyup.enter.exact="nextStep"
              @click:append="showpass = !showpass"
              :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            >
              <template v-slot:label>
                <span class="text-muted">{{
                  $t("AUTH.REG_STEPS.STEP5.label")
                }}</span>
              </template>
            </v-text-field>
          </div>
        </v-stepper-content>
        <v-stepper-content style="height:100%" step="6">
          <div style="flex-grow:1">
            <div class="text-primary font-weight-bolder font-size-h1">
              {{
                $t("AUTH.REG_STEPS.STEP6.title", {
                  name: userForm.full_name.split(" ")[0]
                })
              }}
            </div>
            <v-text-field
              ref="user_bday"
              v-model="userForm.birthday"
              background-color="transparent"
              flat
              :rules="bdayrule"
              lazy-validation
              v-mask="'##/##/####'"
              autofocus
              solo
              @keydown.enter.exact.prevent
              @keyup.enter.exact="nextStep"
            >
              <template v-slot:label>
                <span class="text-muted">{{
                  $t("AUTH.REG_STEPS.STEP6.label")
                }}</span>
              </template>
            </v-text-field>
          </div>
        </v-stepper-content>
        <v-stepper-content style="height:100%" step="7">
          <div style="flex-grow:1">
            <div class="text-primary font-weight-bolder font-size-h1">
              {{
                $t("AUTH.REG_STEPS.STEP7.title", {
                  name: userForm.full_name.split(" ")[0]
                })
              }}
            </div>
            <div
              class="mt-5"
              style="display:flex;justify-content: center;align-items: center;"
            >
              <div
                style="width:40%; border-radius:10px;display:flex;justify-content: center;align-items: center;flex-direction:column"
                :style="
                  userForm.gender == 'male'
                    ? 'border-style: solid;border-width: thin;border-color: #0971ce;'
                    : 'box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;'
                "
                class="mx-2 hover pb-2"
                @click="gender = 'male'"
              >
                <lottie-animation
                  :animationData="require('@/assets/animation/male.json')"
                  :autoPlay="false"
                  ref="playmale"
                />
                <span class="text-primary text-size-h6">{{
                  $t("AUTH.REG_STEPS.STEP7.label[0]")
                }}</span>
              </div>
              <div
                style="width:40%; border-radius:10px;display:flex;justify-content: center;align-items: center;flex-direction:column"
                :style="
                  userForm.gender == 'female'
                    ? 'border-style: solid;border-width: thin;border-color: #0971ce;'
                    : 'box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;'
                "
                class="mx-2 hover pb-2"
                @click="gender = 'female'"
              >
                <lottie-animation
                  :animationData="require('@/assets/animation/female.json')"
                  :autoPlay="false"
                  ref="playfemale"
                />
                <span class="text-primary text-size-h6">{{
                  $t("AUTH.REG_STEPS.STEP7.label[1]")
                }}</span>
              </div>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content style="height:100%" step="8">
          <div style="flex-grow:1">
            <div class="text-primary font-weight-bolder font-size-h1">
              {{ $t("AUTH.REG_STEPS.STEP8.title") }}
            </div>
            <div
              class="mt-5"
              style="display:flex;justify-content: center;align-items: center;flex-wrap: wrap;"
            >
              <div
                v-for="(type, i) in bloodTypes"
                :key="i"
                style="width:40%; border-radius:10px; min-height:60px; display:flex;justify-content: center;align-items: center;"
                :style="
                  userForm.blood_type == type
                    ? 'border-style: solid;border-width: thin;border-color: #0971ce;'
                    : 'box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;'
                "
                class="mx-2 hover mb-4"
                @click="userForm.blood_type = type"
              >
                <div class="text-primary font-weight-bolder font-size-h2">
                  {{ type }}
                </div>
              </div>
            </div>
          </div>
        </v-stepper-content>
        <v-stepper-content style="height:100%" step="9">
          <div style="flex-grow:1">
            <div class="text-primary font-weight-bolder font-size-h1">
              {{
                $t("AUTH.REG_STEPS.summery.label", {
                  name: userForm.full_name.split(" ")[0]
                })
              }}
            </div>
            <div
              class="mt-5"
              style="display:flex;justify-content: center;align-items: center;flex-direction: column;"
            >
              <div
                class="usersummery mx-2 text-primary font-weight-bolder font-size-h6"
              >
                <i
                  class="bx bxs-user-circle text-primary font-size-h1 mx-3"
                ></i>
                {{ userForm.full_name }}
              </div>
              <div
                class="usersummery mx-2 text-primary font-weight-bolder font-size-h6"
              >
                <i class="bx bx-mail-send text-primary font-size-h1 mx-3"></i>
                {{ userForm.email }}
              </div>
              <div
                class="usersummery mx-2 text-primary font-weight-bolder font-size-h6"
              >
                <i class="bx bxs-phone text-primary font-size-h1 mx-3"></i>
                {{ userForm.phone }}
              </div>
              <div
                class="usersummery mx-2 text-primary font-weight-bolder font-size-h6"
              >
                <i class="bx bx-female-sign text-primary font-size-h1 mx-3"></i>
                {{ userForm.gender }}
              </div>
              <div
                class="usersummery mx-2 text-primary font-weight-bolder font-size-h6"
              >
                <i class="bx bxs-droplet text-primary font-size-h1 mx-3"></i>
                {{ userForm.blood_type }}
              </div>
            </div>
          </div>
        </v-stepper-content>
        <div class="regbottomnav" :style="!$root.mobile ? 'width:40vw;' : ''">
          <span
            v-if="totalsteps - step > 0"
            class="px-5 text-primary font-weight-bolder font-size-h3"
            >{{ totalsteps - step }}
            <span class="text-muted font-weight-normal font-size-h5">{{
              $t("AUTH.REG_STEPS.actions.counter")
            }}</span></span
          >
          <v-checkbox
            v-else-if="step == 9"
            hide-details
            v-model="concent"
            color="primary"
          >
            <template v-slot:label
              ><div class="mt-2 font-size-sm text-muted">
                {{ $t("AUTH.REG_STEPS.actions.concent") }}
              </div></template
            ></v-checkbox
          >
          <span
            v-else
            class="px-5 text-muted font-weight-normal font-size-h5"
            >{{ $t("AUTH.REG_STEPS.actions.regstep") }}</span
          >
          <div
            style="display:flex;justify-content: center;align-items: center;"
          >
            <v-btn
              width="45%"
              large
              rounded
              elevation="0"
              class="mt-3 mx-1 white--text"
              color="warning"
              @click="stepback()"
              :disabled="registering"
            >
              {{ $t("AUTH.REG_STEPS.actions.back") }}
            </v-btn>
            <v-btn
              width="45%"
              large
              rounded
              elevation="0"
              class="mt-3 mx-1"
              color="primary"
              @click="nextStep()"
              :disabled="(step == 9 && !concent) || registering"
              :loading="registering"
            >
              <span v-if="step == 9">
                {{ $t("AUTH.REG_STEPS.actions.reg") }}
              </span>
              <span v-else-if="totalsteps - step > 0">
                {{ $t("AUTH.REG_STEPS.actions.continue") }}
              </span>
              <span v-else>
                {{ $t("AUTH.REG_STEPS.actions.confirm") }}
              </span>
            </v-btn>
          </div>
        </div>
      </v-stepper-items>
    </v-stepper>
    <div
      v-else
      style="height:50vh; display:flex;justify-content: center;align-items: center;"
    >
      <div
        v-if="!didregister"
        style="display:flex;justify-content: center;align-items: center;flex-direction:column"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          size="50"
          class="mb-5"
        />
        <div class="text-muted text-center">
          {{ $t("AUTH.REG_STEPS.actions.reging") }}
        </div>
      </div>
      <div
        style="display:flex;justify-content: center;align-items: center;flex-direction:column"
        v-else
      >
        <lottie-animation
          style="height:100px"
          :animationData="require('@/assets/animation/confetti.json')"
          :loop="true"
        />
        <div class="text-muted text-center">
          {{
            $t("AUTH.REG_STEPS.actions.welcome", { name: userForm.first_name })
          }}
        </div>
      </div>
    </div>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          {{ $t("AUTH.REG_STEPS.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-bottom-sheet v-model="show_email_code_input">
      <v-card elevation="0">
        <v-toolbar elevation="0" class="text-primary">
          <v-toolbar-title>
            {{ $t("AUTH.REG_STEPS.varifyEmail.title") }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            color="primary"
            @click="show_email_code_input = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="">
          <span style="font-size:1rem;">{{
            $t("AUTH.REG_STEPS.varifyEmail.label")
          }}</span>
          <div
            class="mt-2"
            style="display:flex;justify-content: center;align-items: center;"
          >
            <v-text-field
              ref="otp1"
              outlined
              v-model="otp.s1.input"
              autofocus
              @input="stepinput(1)"
              @keyup.backspace.exact="inputback(1)"
              @keydown.backspace.exact.prevent
              hide-details
              :class="$root.lang == 'ar' ? 'ml-4' : 'mr-4'"
              height="50px"
              v-mask="'#'"
              @paste="onPaste"
            />
            <v-text-field
              ref="otp2"
              outlined
              v-model="otp.s2.input"
              hide-details
              class="mx-4"
              height="50px"
              v-mask="'#'"
              @input="stepinput(2)"
              @keyup.backspace.exact="inputback(2)"
              @keydown.backspace.exact.prevent
            />
            <v-text-field
              ref="otp3"
              outlined
              v-model="otp.s3.input"
              hide-details
              class="mx-4"
              height="50px"
              v-mask="'#'"
              @input="stepinput(3)"
              @keyup.backspace.exact="inputback(3)"
              @keydown.backspace.exact.prevent
            />
            <v-text-field
              ref="otp4"
              outlined
              v-model="otp.s4.input"
              hide-details
              :class="$root.lang == 'ar' ? 'mr-4' : 'ml-4'"
              height="50px"
              v-mask="'#'"
              @input="stepinput(4)"
              @keyup.backspace.exact="inputback(4)"
              @keydown.backspace.exact.prevent
              @keydown.enter.exact.prevent
              @keyup.enter.exact="checkcode()"
            />
          </div>
        </v-card-text>
        <v-card-actions
          style="display:flex;justify-content: center;align-items: center;"
        >
          <v-btn
            width="45%"
            rounded
            elevation="0"
            class="mx-1 white--text"
            color="warning"
            @click="sendotp"
          >
            {{ $t("AUTH.REG_STEPS.varifyEmail.resend") }}
          </v-btn>
          <v-btn
            width="45%"
            rounded
            elevation="0"
            class="mx-1 white--text"
            color="primary"
            @click="checkcode()"
          >
            {{ $t("AUTH.REG_STEPS.varifyEmail.verify") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  // mixins: [validationMixin],
  name: "registerdoc",
  components: { LottieAnimation },
  data() {
    return {
      step: 1,
      totalsteps: 8,
      showpass: false,
      registering: false,
      didregister: false,
      snackbar: false,
      concent: false,
      otp: {
        s1: {
          focus: true,
          input: ""
        },
        s2: {
          focus: false,
          input: ""
        },
        s3: {
          focus: false,
          input: ""
        },
        s4: {
          focus: false,
          input: ""
        }
      },
      otp1: true,
      otp2: false,
      otp3: false,
      otp4: false,
      email_code: "",
      show_email_code_input: false,
      error: "",
      userForm: {
        full_name: "",
        password: "",
        first_name: "",
        last_name: "",
        confirmPassword: "",
        email: "",
        phone: "",
        gender: "",
        birthday: "",
        blood_type: "",
        verify_email: "false",
        timezone: this.$util.getTimezone(),
        role: "patient",
        nationality: "",
        national_id: ""
      },
      gender: "",
      nameRules: [
        v => !!v || this.$t("AUTH.REG_STEPS.STEP1.validation[0]"),
        v => {
          const pattern = /^[^\s]+( [^\s]+)+$/;
          return (
            pattern.test(v) || this.$t("AUTH.REG_STEPS.STEP1.validation[1]")
          );
        }
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "E-mail must be valid";
        }
      ],
      bdayrule: [
        v => !!v || "Birthday is required",
        v => {
          const pattern = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
          return pattern.test(v) || "Birthday must be valid";
        }
      ],
      phoneRules: [
        v => !!v || "Phone Number is required",
        v =>
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(v) ||
          "Invalid Phone Number"
      ],
      confirmPasswordRules: [
        v => !!v || "Confirm Password is required",
        v => v === this.userForm.password || "password must be matched"
      ],

      // =================== patient info ===================

      bloodTypes: ["O+", "O-", "B+", "B-", "A+", "A-", "AB+", "AB-"]
    };
  },

  methods: {
    nextStep() {
      switch (this.step) {
        case 1: {
          if (this.$refs.user_name.valid) {
            // console.log("All Good name");
            this.step++;
          }
          break;
        }
        case 2: {
          if (this.$refs.user_email.valid) {
            // console.log("All Good email");
            this.sendotp();
            this.show_email_code_input = true;
          }
          break;
        }
        case 3: {
          if (this.$refs.user_phone.valid) {
            // console.log("All Good number");
            this.step++;
          }
          break;
        }
        case 4: {
          if (this.$refs.user_pass.valid) {
            // console.log("All Good password");
            this.step++;
          }
          break;
        }
        case 5: {
          if (this.$refs.user_passr.valid) {
            // console.log("All Good password");
            this.step++;
          }
          break;
        }
        case 6: {
          if (this.$refs.user_bday.valid) {
            // console.log("All Good password");
            this.step++;
          }
          break;
        }
        case 7: {
          if (this.userForm.gender != "") {
            // console.log("All Good gender");
            this.step++;
          } else {
            // console.log("Not good, no gender");
            this.error = "Please select a gender to proceed";
            this.snackbar = true;
          }
          break;
        }
        case 8: {
          if (this.userForm.blood_type != "") {
            // console.log("All Good bloodtype");
            this.step++;
          } else {
            // console.log("Not good, no bloodtype");
            this.error = "Please select a blood type to proceed";
            this.snackbar = true;
          }
          break;
        }
        case 9: {
          this.registering = true;
          this.registerpatient();
          break;
        }
      }
    },
    stepback() {
      if (this.step > 1) {
        this.step--;
      } else {
        this.$router.go(-1);
      }
    },
    stepinput(s) {
      switch (s) {
        case 1: {
          this.$refs.otp2.focus();
          break;
        }
        case 2: {
          this.$refs.otp3.focus();
          break;
        }
        case 3: {
          this.$refs.otp4.focus();
          break;
        }
        case 4: {
          break;
        }
      }
    },
    inputback(s) {
      switch (s) {
        case 1: {
          this.otp.s1.input = "";
          break;
        }
        case 2: {
          this.otp.s2.input = "";
          this.$refs.otp1.focus();
          break;
        }
        case 3: {
          this.otp.s3.input = "";
          this.$refs.otp2.focus();
          break;
        }
        case 4: {
          this.otp.s4.input = "";
          this.$refs.otp3.focus();
          break;
        }
      }
    },
    onPaste(e) {
      var value = e.clipboardData.getData("text/plain");
      for (var i = 0; i < value.length && i < 4; i++) {
        this.otp["s" + (i + 1)].input = value[i];
        // console.log(this.otp['s'+(i+1)].input)
      }
    },
    checkcode() {
      if (
        this.otp.s1.input +
          this.otp.s2.input +
          this.otp.s3.input +
          this.otp.s4.input ==
        this.email_code
      ) {
        this.show_email_code_input = false;
        this.step++;
      } else {
        this.error = "Incorrect code, please try again";
        this.snackbar = true;
      }
    },
    sendotp() {
      this.email_code = Math.floor(1000 + Math.random() * 9000);
      // console.log(this.email_code)
      this.$notify.notifyByMail(
        "Your Varification Code is: " + this.email_code,
        "Hello " +
          this.userForm.full_name.split(" ")[0] +
          "<br/>Your Doctory verfication code is " +
          this.email_code,
        { email: this.userForm.email }
      );
    },

    ...mapActions(["signout", "signup"]),

    async registerpatient() {
      var splitbday = this.userForm.birthday.split("/");
      this.userForm.birthday =
        splitbday[2] + "-" + splitbday[1] + "-" + splitbday[0];
      var splitname = this.userForm.full_name.split(" ");
      this.userForm.first_name = splitname[0];
      this.userForm.last_name = splitname[splitname.length - 1];
      // console.log("userform to be submitted", this.userForm);
      await this.signup(this.userForm)
        .then(() => {
          this.didregister = true;
          // console.log("data", data);
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 1000);
        })
        .catch(error => {
          this.error = error;
          this.snackbar = true;
        });
      /*setTimeout(() => {
            this.didregister = true;
            this.registering = false;
          }, 1000);*/
    }
  },
  watch: {
    gender: function(updated) {
      // console.log("updated gender", updated);
      if (updated == "male") {
        // console.log("male selected");
        this.userForm.gender = updated;
        this.$refs.playmale.play();
      } else if (updated == "female") {
        // console.log("female selected");
        this.userForm.gender = updated;
        this.$refs.playfemale.play();
      }
    }
  },
  mounted() {},
  computed: {}
};
</script>

<style scoped>
.theme--light >>> .v-stepper__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.theme--light.v-text-field--solo >>> .v-input__control > .v-input__slot {
  padding: 0px !important;
}

.theme--light.v-text-field--outlined
  >>> .v-input__control
  > .v-input__slot
  input {
  text-align: center;
}

.theme--light.v-text-field--solo
  >>> .v-input__control
  > .v-text-field__details {
  padding: 0px !important;
}
</style>
